// ИНСТРУМЕНТЫ
export const SET_TOOL_LIST = 'SET_TOOL_LIST'
export const SET_TOOL_INFO = 'SET_TOOL_INFO'
// ПЕРЕМЕННЫЕ
export const SET_VAR_LIST = 'SET_VAR_LIST'
// ВОПРОСЫ
export const SET_QUESTION_LIST = 'SET_QUESTION_LIST'
// ИНТЕРПРЕТАЦИИ
export const SET_INTERPRETATION_LIST = 'SET_INTERPRETATION_LIST'
// ШАБЛОНЫ ОТЧЁТОВ
export const SET_REPORT_TEMPLATE_LIST = 'SET_REPORT_TEMPLATE_LIST'
// ОТЧЁТЫ
export const SET_REPORT = 'SET_REPORT'

// СОБЫТИЯ
export const SET_EVENT_INFO = 'SET_EVENT_INFO'
export const SET_EVENT_LIST = 'SET_EVENT_LIST'
// ПРИГЛАШЕНИЯ
export const SET_INVITE_LIST = 'SET_INVITE_LIST'
export const SET_INVITE_QUESTION_LIST = 'SET_INVITE_QUESTION_LIST'
// РЕЗУЛЬТАТЫ
export const SET_RESULT_LIST = 'SET_RESULT_LIST'
export const SET_RESULT_INFO = 'SET_RESULT_INFO'

// ПОЛЬЗОВАТЕЛИ
export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const SET_USER_IN_API = 'SET_USER_IN_API'
export const SET_USER_LIST = 'SET_USER_LIST'
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST'
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA'

// СТАТУС
export const SET_ACTION_STATUS = 'SET_ACTION_STATUS'
export const REMOVE_ACTION_STATUS = 'REMOVE_ACTION_STATUS'

// УВЕДОМЛЕНИЯ
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

// РЕЙТИНГ
export const SET_RATING_DATA = 'SET_RATING_DATA'
export const SET_RATING_STATE = 'SET_RATING_STATE'

// ЗАМЕТКИ
export const SET_NOTES_DATA = 'SET_NOTES_DATA'
export const SET_NOTES_STATE = 'SET_NOTES_STATE'




