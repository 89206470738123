import {
    CLOSE_SNACKBAR,
    ENQUEUE_SNACKBAR,
    REMOVE_ACTION_STATUS,
    REMOVE_SNACKBAR,
    SET_ACTION_STATUS,
    SET_EVENT_INFO,
    SET_EVENT_LIST,
    SET_INTERPRETATION_LIST,
    SET_INVITE_LIST,
    SET_INVITE_QUESTION_LIST,
    SET_NOTES_DATA,
    SET_NOTES_STATE,
    SET_QUESTION_LIST,
    SET_RATING_DATA,
    SET_RATING_STATE,
    SET_REPORT,
    SET_REPORT_TEMPLATE_LIST,
    SET_RESULT_INFO,
    SET_RESULT_LIST,
    SET_TOOL_INFO,
    SET_TOOL_LIST,
    SET_VAR_LIST
} from "../actions/actionTypes";
import {ActionStatus} from "../../utilities/types/action_status";
import {BaseAction, BaseState} from "../../utilities/types/state";

const baseStateDefault = {} as BaseState
export const BaseReducer = (state = baseStateDefault, action: BaseAction): BaseState => {
    let action_status_state_map: Map<string, ActionStatus>
    let new_action_status_state: ActionStatus
    switch (action.type) {
        case SET_TOOL_LIST:
            return {...state, tool_list: action.tool_list};
        case SET_TOOL_INFO:
            return {...state, tool_info: action.tool_info};
        case SET_VAR_LIST:
            return {
                ...state,
                var_list: action.var_list
            };
        case SET_QUESTION_LIST:
            return {...state, question_list: action.question_list};
        case SET_EVENT_LIST:
            return {...state, event_list: action.event_list};
        case SET_EVENT_INFO:
            return {...state, event_info: action.event_info};
        case SET_INVITE_LIST:
            return {
                ...state,
                invite_list: action.invite_list
            };
        case SET_INVITE_QUESTION_LIST:
            return {
                ...state,
                invite_question_list: action.invite_question_list
            }
        case SET_ACTION_STATUS:
            // Update status and clear another variableList of action's status by default
            new_action_status_state = {
                isLoading: null,
                isError: null,
                isSuccess: null,
                isComplete: ((action.status === 'isSuccess' || action.status === 'isError') && action.status_value !== null),
                [action.status]: action.status_value
            }
            if (state.actionStatus) {
                // Copy Map with statuses of actions
                action_status_state_map = new Map(state.actionStatus)
                // Does Map has locales of this action?
                if (action_status_state_map.has(action.action)) {
                    let current_action_status_state = action_status_state_map.get(action.action) as ActionStatus
                    // Does this value of action status changes?
                    if (current_action_status_state[action.status] === action.status_value) {
                        // Values are not different. Do not touch this
                        return state
                    }
                }
            } else {
                // Create empty Map with statuses of actions
                action_status_state_map = new Map()
            }
            return {
                ...state,
                actionStatus: action_status_state_map.set(action.action, new_action_status_state)
            }
        case REMOVE_ACTION_STATUS:
            action_status_state_map = (state.actionStatus ? new Map(state.actionStatus) : new Map())
            if (action_status_state_map.has(action.action)) {
                action_status_state_map.delete(action.action)
            } else {
                return state
            }
            return {
                ...state,
                actionStatus: action_status_state_map
            }
        case SET_INTERPRETATION_LIST:
            return {
                ...state,
                interpretation_list: action.interpretation_list
            }
        case SET_REPORT_TEMPLATE_LIST:
            return {
                ...state,
                report_template_list: action.report_template_list
            }
        case SET_RESULT_LIST:
            return {
                ...state,
                result_list: action.result_list
            }
        case SET_RESULT_INFO:
            return {
                ...state,
                result_info: action.result_info
            }
        case SET_REPORT:
            return {
                ...state,
                report: action.report
            }
        case ENQUEUE_SNACKBAR:
            if (state.notifications) {
                return {
                    ...state,
                    notifications: [
                        ...state.notifications,
                        {
                            key: action.key,
                            ...action.notification
                        }
                    ]
                }
            } else {
                return {
                    ...state,
                    notifications: [
                        {
                            key: action.key,
                            ...action.notification
                        }
                    ]
                }
            }
        case CLOSE_SNACKBAR:
            if (state.notifications) {
                return {
                    ...state,
                    notifications: state.notifications.map(notification => (
                        (action.dismissAll || notification.key === action.key)
                            ? {...notification, dismissed: true}
                            : {...notification}
                    ))
                }
            } else {
                return state
            }
        case REMOVE_SNACKBAR:
            if (state.notifications) {
                return {
                    ...state,
                    notifications: state.notifications.filter(
                        notification => notification.key !== action.key
                    )
                }
            } else {
                return state
            }
        case SET_RATING_DATA:
            if (state.rating) {
                return {
                    ...state,
                    rating: {
                        ...state.rating,
                        [action.entityType]: new Map(action.ratingData)
                    }
                }
            } else {
                return {
                    ...state,
                    rating: {
                        [action.entityType]: new Map(action.ratingData)
                    }
                } as BaseState
            }
        case SET_RATING_STATE:
            return {
                ...state,
                rating: action.ratingState
            } as BaseState
        case SET_NOTES_DATA:
            if (state.notes) {
                return {
                    ...state,
                    notes: {
                        ...state.notes,
                        [action.entityType]: new Map(action.notesData)
                    }
                }
            } else {
                return {
                    ...state,
                    notes: {
                        [action.entityType]: new Map(action.notesData)
                    }
                } as BaseState
            }
        case SET_NOTES_STATE:
            return {
                ...state,
                notes: action.notesState
            } as BaseState
        default:
            return state
    }
}

