import {
    REMOVE_USER_DATA,
    SET_USER_IN_API,
    SET_USER_LIST,
    SET_USER_PROFILE,
    UPDATE_USER_LIST
} from "../actions/actionTypes";
import {UserListItem} from "../../utilities/types/users";
import {UserAction, UserState} from "../../utilities/types/state";

const userStateDefault = {} as UserState

const UserReducer = (state = userStateDefault, action: UserAction): UserState => {
    switch (action.type) {
        case SET_USER_PROFILE:
            return {
                ...state,
                user_profile: action.user_profile
            }
        case SET_USER_IN_API:
            return {
                ...state,
                user_in_api: action.user_in_api
            }
        case SET_USER_LIST:
            return {
                ...state,
                user_list: action.user_list
            }
        case REMOVE_USER_DATA:
            return {
                ...state,
                user_profile: null,
                user_in_api: null,
            }
        case UPDATE_USER_LIST:
            if (state.user_list) {
                const current_user_list = state.user_list as Array<UserListItem>
                return {
                    ...state,
                    user_list: [
                        ...current_user_list,
                        ...action.user_list.filter(
                            (user_list_item: UserListItem) => current_user_list.filter(
                                (user) => user.id === user_list_item.id
                            ).length === 0)
                    ]
                }
            } else {
                return {
                    ...state,
                    user_list: action.user_list
                }
            }
        default:
            return state
    }
}

export default UserReducer

