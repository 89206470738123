import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import blueGrey from "@material-ui/core/colors/blueGrey";

const themeSite = createMuiTheme({
    palette: {
        primary: {
            light: '#1B69DE',
            main: '#1F14F5',
            dark: '#2204BA',
            contrastText: '#fff',
        },
        secondary: blueGrey,
        background: {
            paper: "#ffffff",
            default: "#ffffff"
        }
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
            },
            contained: {
                borderRadius: '7px'
            },
            text: {},
        },
        MuiGrid: {
            "grid-xs-12": {
                maxWidth: 'none'
            }
        },
        MuiCard: {
            root: {
                borderRadius: '15px'
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '1rem'
            }
        }
    },
    typography: {
        fontSize: 16,
        h1: {
            fontFamily: [
                '"Montserrat"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        h2: {
            fontFamily: [
                '"Montserrat"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        h3: {
            fontFamily: [
                '"Montserrat"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            fontWeight: 600,
        },
        h4: {
            fontFamily: [
                '"Montserrat"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        h5: {
            fontFamily: [
                '"Montserrat"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        h6: {
            fontFamily: [
                '"Montserrat"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        }

    }
})

export default themeSite