export const wwwLink = 'http://localhost:3000' // http://localhost:3000   https://impsy.ru

export const assessmentManagementAppLink = '/ama'

export const toolListLink = `${assessmentManagementAppLink}/tools`
export const toolDetailsLink = (toolId: string) => `${toolListLink}/:tool_guid`.replace(/:[a-zA-z0-9_]+/, toolId)
export const variableListLink = (toolId: string) => `${toolDetailsLink(toolId)}/variables`
export const questionListLink = (toolId: string) => `${toolDetailsLink(toolId)}/questions`
export const interpretationListLink = (toolId: string) => `${toolDetailsLink(toolId)}/interpretations`
export const reportTemplateListLink = (toolId: string) => `${toolDetailsLink(toolId)}/report_templates`
export const reportTemplateDetailsLink = (toolId: string, templateId: string) => `${toolDetailsLink(toolId)}/report_template/${templateId}`

export const eventListLink = `${assessmentManagementAppLink}/events`
export const eventDetailsLink = (eventId: string) => `${eventListLink}/:eventId`.replace(/:[a-zA-z0-9_]+/, eventId)
export const inviteListLink = (eventId: string) => `${eventDetailsLink(eventId)}/invites`
export const resultListLink = (eventId: string) => `${eventDetailsLink(eventId)}/results`
export const resultDetailsLink = (eventId: string, resultId: string) => `${resultListLink(eventId)}/${resultId}`
export const resultReportDetailsLink = (eventId: string, resultId: string, reportId: string) => `${resultDetailsLink(eventId, resultId)}/report/${reportId}`

export const participantListLink = `${assessmentManagementAppLink}/participants`

export const userListLink = `${assessmentManagementAppLink}/users`

export const siteAppLink = '/'

export const loginLink = `${siteAppLink}login`
export const registerLink = `${siteAppLink}register`
export const forgotPasswordLink = `${siteAppLink}forgot`
export const accountLink = `${siteAppLink}account`

export const assessmentAppLink = '/t'
export const assessmentByInviteLink = (inviteId: string) => `${wwwLink}${assessmentAppLink}/:inviteId`.replace(/:[a-zA-z]+/, inviteId)
export const assessmentInviteLink = (inviteId: string) => `https://impsy.ru/:inviteId`.replace(/:[a-zA-z]+/, inviteId)












