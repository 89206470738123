import UserReducer from "./User"
import {BaseReducer} from "./Base"
import {combineReducers} from "redux"

const rootReducer = combineReducers({
    BaseData: BaseReducer,
    UserData: UserReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer