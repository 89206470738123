import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";
import {useSnackbar} from "notistack";
import React from "react";
import {removeSnackbar} from "../../../store/actions/Notification";

let displayed: any = []

const Notifier: React.FunctionComponent<{}> = props => {
    const dispatch = useDispatch()
    const notifications = useSelector((state: RootState) => state.BaseData.notifications || [])
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    const storeDisplayed = (id: any) => {
        displayed = [...displayed, id]
    }

    const removeDisplayed = (id: any) => {
        displayed = [...displayed.filter((key: any) => id !== key)]
    }

    React.useEffect(() => {
        notifications.forEach(({key, message, options = {}, dismissed = false}) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key)
                return
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // removen this snackbar from redux store
                    dispatch(removeSnackbar(myKey))
                    removeDisplayed(myKey);
                }
            })

            // keep track of snackbars that we've displayed
            storeDisplayed(key)
        })
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

    return null
}

export default Notifier