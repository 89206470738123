import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import store from "./store/store";
import {Provider} from "react-redux";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {SnackbarProvider} from 'notistack';
import Notifier from "./_AssessmentManagementApp/components/common/Notifier";
import {CircularProgress, Container, CssBaseline, Grid, ThemeProvider} from "@material-ui/core";
import {assessmentManagementAppLink, siteAppLink} from "./utilities/constants/links";
import themeSite from "./utilities/themes/themeSite";

const AssessmentManagementApp = React.lazy(() => import("./_AssessmentManagementApp/AssessmentManagementApp"))
const AssessmentApp = React.lazy(() => import("./_AssessmentApp/AssessmentApp"))
const SiteApp = React.lazy(() => import("./_SiteApp/SiteApp"))

const Loader: React.FC<{}> = props => (
    <ThemeProvider theme={themeSite}>
        <CssBaseline/>
        <Container maxWidth="sm" style={{paddingTop: '5rem'}}>
            <Grid container justify={"center"}>
                <CircularProgress/>
                <Grid item xs={12} container justify={"center"}>
                    <p>Загружаем модуль</p>
                </Grid>
            </Grid>
        </Container>
    </ThemeProvider>
)

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <SnackbarProvider>
                    <CssBaseline/>
                    <Notifier/>
                    <Switch>
                        <Route
                            path={assessmentManagementAppLink}
                            render={
                                () =>
                                    <Suspense fallback={<Loader/>}>
                                        <AssessmentManagementApp/>
                                    </Suspense>
                            }/>
                        <Route exact path={'/t/:inviteId'} render={
                            () =>
                                <Suspense fallback={<Loader/>}>
                                    <AssessmentApp/>
                                </Suspense>
                        }/>
                        <Route path={siteAppLink} render={
                            () =>
                                <Suspense fallback={<Loader/>}>
                                    <SiteApp/>
                                </Suspense>
                        }/>
                    </Switch>
                </SnackbarProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
)

serviceWorker.register()
